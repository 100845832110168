<template>
  <div>
    <a-modal :visible="visible" shape="round" :closable="false" on-ok="handleOk" centered>
      <template slot="footer">
        <div class="w-full max-w-sm mx-auto pb-5 grid gap-2">
          <a-button key="back" type="danger" shape="round" ghost :block="true" @click="handleCancel">
            CANCELAR
          </a-button>
          <a-button key="submit" type="primary" shape="round" :block="true" :loading="loading" @click="handleOk">
            INACTIVAR USUARIOS
          </a-button>
        </div>
      </template>
      <div class="text-center pt-2">
        <h3 class="mb-4 text-2xl text-blue-dark"><b>Inactivar usuarios</b></h3>
        <p>¿Está seguro de que desea inactivar los {{users.length}} usuarios seleccionados?</p>
        <br>
        <p>Recuerde que para deshacer esta acción deberá activar los usuarios de forma individual.</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    users: [],
    visible: Boolean,
    data: []
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      this.loading = true;
      let {error, data} = await this.$api.inactivateMassiveUsers(this.$props.users);
      this.loading = false;

      if (error) this.showToast("error", "Error inactivando masivamente los usuarios.");

      if (data) {
        this.showToast('success', 'Inactivación éxitosa.');
        this.$emit('toggle-modal',  {toggle: false, reload: true });
      }
    },
    handleCancel(e) {
      this.$emit('toggle-modal',  {toggle: false, reload: false });
    },
    showToast(result = 'info', msg) {
      this.$notification[result]({
                message: 'Resultado',
                description: msg,
                onClick: () => {},
            });
    }
  },
};
</script>
