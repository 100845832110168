<template>
  <div class="w-full">
    <div style="margin-bottom: 16px">
      <UsersTableHeader
        :showButton="hasSelected"
        @toggle-modal="toggleModal"
        @search-term="onSearchTerm"
        :search="search"
      />
    </div>
    <div v-if="!loading">
    <div
      class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white users"
    >
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
      >
        <span
          slot="actions_name"
          slot-scope="text, record"
          class="text-sm font-medium text-right whitespace-nowrap flex"
        >
          {{ record.first_name }} {{ record.last_name }}
        </span>

        <span
          slot="actions"
          slot-scope="text, record"
          class="mr-4 text-sm font-medium text-right whitespace-nowrap flex"
        >
          <a-switch
            v-model="record.is_active"
            @change="onSwitchChange($event, record)"
          />
          <router-link
            :to="`./edit/?id=${record.id}`"
            class="
              ml-3
              h-6
              w-6
              p-1
              text-blue-600
              dark:text-blue-500
              shadow
              rounded
            "
            append
          >
            <img class="h-full w-full" src="@/assets/icons/pen.svg" alt="" />
          </router-link>
        </span>
      </a-table>
    </div>
    <div class="mt-4 flex justify-end">
      <a-pagination
        v-model="page"
        :page-size="pageSize"
        :page-size-options="pageSizeOptions"
        :total="count"
        show-size-changer
        size="large"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    </div>
      <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0 ">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <ModalConfirmation
      :visible="showModal"
      :users="selectedRowKeys"
      @toggle-modal="toggleModal"
      :data="data"
    />
  </div>
</template>
<script>
import UsersTableHeader from "@/components/Dashboard/Users/UsersTableHeader";
import ModalConfirmation from "@/components/Dashboard/Users/ModalConfirmation.vue";

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "actions_name" },
  },
  {
    title: "Tipo de Id.",
    dataIndex: "person.type_identification_name",
    key: "person.type_identification_name",
  },
  {
    title: "No. de Id",
    dataIndex: "person.identification",
    key: "person.identification",
  },
  {
    title: "Correo electrónico",
    dataIndex: "email",
    ley: "email",
  },
  {
    title: "Rol",
    dataIndex: "group_name",
    key: "group_name",
  },/*
  {
    title: "Dirección",
    dataIndex: "direction",
    key: "direction",
  },*/
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: {
    ModalConfirmation,
    UsersTableHeader,
    
  },
  data() {
    return {
      data: [],
      columns,
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      current: null,
      showModal: false,
      searchTerm: "",
      page: 1,
      pageSize: 20,
      count: 0,
      query: "",
      search: null,
      pageSizeOptions: ['20', '40', '60', '80', '100']
    };
  },
  created() {
    if (this.$route.query.search) {
      this.searchTerm = this.$route.query.search;
      this.search = this.searchTerm;
    }
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
     if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 20;
    }
    this.start();
  },

  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, search: this.searchTerm, pageSize:this.pageSize },
        });
      }
    },
    pageSize: function(newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, search: this.searchTerm, pageSize:this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.start();
    },
  },
  methods: {
    onSearchTerm(value) {
      this.showToast("info", "Buscando resultados...");
      this.searchTerm = value?.trim();
      this.search = this.searchTerm;
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.$router.replace({
          query: { page: this.page, search: this.searchTerm },
        });
        this.start();
      }
      this.page = 1;
    },
    async start() {
      if (this.loading) return;
      let query = `?page=${this.page}&page_size=${this.pageSize}`;
      if (this.searchTerm) query += `&search=${this.searchTerm}`;

      this.loading = true;
      let { error, data } = await this.$api.readUser(query);
      this.loading = false;

      if (error) {
        this.data = [];
        this.showToast("error", "Error consultando la lista de usuarios.");
      }

      if (data) {
        this.data = data.results/*.map((item) => {
          return {
            ...item,
            direction: this.getDirectionName(item.direction),
          };
        });*/
        this.count = data.count;
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async onSwitchChange(checked, record) {
      this.showToast("info", "Procesando cambio de estado...");
      this.loading = true;
      let { error, data } = await this.$api.changeStateUser(
        { state: checked },
        record.id
      );
      this.loading = false;

      if (error) {
        const index = this.data.findIndex(item => item.id == record.id);
        this.data[index].is_active = !checked;
        this.showToast("error", error ? error.message : "Error cambiando estado del usuario.");
      }

      if (data) {
        this.showToast("success", "Cambio de estado éxitoso.");
        this.start();
      }
    },
    getDirectionName(dir) {
      const directionName = this.formOptions.optionsDirections.find(
        (direction) => direction.id == dir
      );
      return directionName ? directionName.name : "Sin dirección";
    },
    toggleModal(value) {
      this.showModal = value?.toggle;
      if (value?.reload) {
        this.start();
      }
    },
    onShowSizeChange(page, page_size) {
      this.page = page
      this.pageSize = page_size
      this.start()
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
};
</script>
<style>
.users .ant-table-thead > tr > th {
  font-weight: bold;
  color:#6b7280;
}
/* .users .ant-table-tbody > tr > td {
  text-align: center;
}*/
</style> 